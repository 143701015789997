<template>
  <el-dialog 
    :title="title" 
    :visible="visible" 
    :append-to-body="true"
    :modal-append-to-body="false"
    @close="closeDialog">
    <el-form
      label-width="200px"
      :model="formModel"
      :rules="formRules"
      ref="formRef"
    >
      <el-form-item label="草稿要转移到的个号" prop="numId">
        <el-select
          v-model="formModel.numId"
          placeholder="请选择草稿要转移到的个号"
          @change="changeNum"
        >
          <el-option
            v-for="(item, index) in validNumLists"
            :key="index"
            :label="`${
              item.thirdUser.displayName
                ? item.thirdUser.displayName
                : item.thirdUser.thirdUserName
            }（ ${item.thirdUser.thirdUserEmail}）`"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="草稿要转移到的广告账户" prop="accountId">
        <el-select
          v-model="formModel.accountId"
          placeholder="请选择草稿要转移到的广告账户"
          popper-class="popper-class"
        >
          <el-option
            v-for="(item, index) in accounts"
            :key="index"
            :label="item.name"
            :value="item.id"
          >
            <p>{{ item.name }}</p>
            <p class="accounts-id">ID：{{ item.id }}</p>
          </el-option>
          <div slot="empty" class="empty" v-loading="loadingAccount">
            <span>
              <i class="iconfont icon-wushuju"></i>
              <p>暂无数据</p>
            </span>
          </div>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleCancel">取消</el-button>
      <el-button @click="handleConfirm" type="primary">提交</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { adaccouts } from "@/api/common/user";
import { draftsTransfer } from "@/api/adManagement";
export default {
  name: "draftMove",
  props: {
    title: {
      type: String,
      default: () => "标题",
    },
    visible: {
      type: Boolean,
      default: () => false,
    },
    selDraftIds: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      formModel: {
        numId: "",
        accountId: "",
      },
      formRules: {
        numId: {
          required: true,
          message: "请选择草稿要转移到的个号！",
          trigger: ["blur", "change"],
        },
        accountId: {
          required: true,
          message: "请选择草稿要转移到的广告账户！",
          trigger: ["blur", "change"],
        },
      },
      accounts: [],
      loadingAccount: false,
    };
  },
  computed: {
    ...mapGetters("num", ["validNumLists"]),
  },
  methods: {
    draftTransfer(accountId) {
      this.$showLoading = true;
      let params = {
        draftCampaignIds: this.selDraftIds.join(","),
        toAccountId: accountId,
      };
      draftsTransfer(params).then((res) => {
        this.$hideLoading = false;
        if (res.code == 0) {
          let obj = this.accounts.find((item) => item.id == accountId),
            name = obj.name;
          this.$message({
            type: "success",
            message: `草稿系列已转移到广告账户：${name}！`,
          });
          this.$confirm(
            "进入草稿系列转移目标广告账户的草稿箱还是继续操作本页面?",
            "提示",
            {
              confirmButtonText: "进入目标广告账户草稿箱",
              cancelButtonText: "继续操作本页面",
              type: "warning",
            }
          )
            .then(() => {
              this.$emit("close-dialog");
              this.$router.push({
                name: "adManagement",
                query: { obj },
              });
            })
            .catch(() => {
              this.$emit("close-dialog-refresh-list");
            });
        }
      });
    },
    closeDialog() {
      this.$emit("close-dialog");
    },
    getAccounts(numId) {
      this.loadingAccount = true;
      let params = {
        keyword: "",
        limit: 10000,
        page: 1,
        id: numId,
      };
      adaccouts(params).then((res) => {
        this.loadingAccount = false;
        if (res.code == 200) {
          this.accounts = res.data && res.data.list ? res.data.list : [];
          console.log(this.accounts)
        }
      });
    },
    changeNum(val) {
      this.getAccounts(val);
    },
    handleCancel() {
      this.$emit("close-dialog");
    },
    handleConfirm() {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          this.draftTransfer(this.formModel.accountId);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.popper-class {
  .el-select-dropdown__item {
    height: 65px;
    .accounts-id {
      font-size: 12px;
      line-height: 20px;
      color: #bdbcbc;
      font-weight: 300;
    }
  }
}

</style>